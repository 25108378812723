import Image from '@components/Image'
import classnames from '@helpers/misc/classnames'
import {FirstCategoryContext} from '@helpers/websites/FirstCategoryContext'
import React, {useContext} from 'react'

import {ProductCardOrientation} from '..'
import {ProductWithStockInfo} from '../../../hooks/useAddStockInfoToProducts.main'
export interface Props {
  product: ProductWithStockInfo
  disabledProduct: boolean
  cardOrientation: ProductCardOrientation
  horizontallyReversed?: boolean
}

function ProductImage(props: Props) {
  const {product, disabledProduct, cardOrientation, horizontallyReversed = false} = props
  const {images, titleImg, altImg, name} = product
  const isInPageFold = useContext(FirstCategoryContext)
  const isVertical = cardOrientation === 'vertical'
  const isHorizontal = cardOrientation === 'horizontal'
  const imageBorders = isVertical
    ? 'rounded-t-lg'
    : horizontallyReversed
      ? 'rounded-r-lg'
      : 'rounded-l-lg'

  return (
    <div
      className={classnames('relative', {
        'h-40 w-full': isVertical,
        'w-[40%] sm:w-40': isHorizontal,
        'opacity-40': disabledProduct,
      })}
    >
      <Image
        src={images?.length && images[0]?.imageSmall}
        alt={altImg || name}
        title={titleImg || name}
        layout="fill"
        objectFit="cover"
        priority={isInPageFold}
        className={imageBorders}
      />
    </div>
  )
}

export default ProductImage
