import gql from 'graphql-tag'
export const productListInformationWithoutStockFragment = gql`
  fragment productListInformationWithoutStock on Product {
    _id
    name
    description
    nicknames
    altImg
    titleImg
    images {
      _id
      url
      # colors {
      #   blurhash4x4
      # }
      imageSmall: resizedURL(width: 320, height: 320, format: $format)
    }
    availabilityAt(menuId: $menuId) {
      basePrice
      finalPrice
      pastPrices
      hasDiscount
      available
      notAvailableMessage
      hideDiscountBadge
    }
    menu(menuId: $menuId) {
      _id
      channels
    }
    modifiers {
      _id
      description
      isImageModifier
      max
      min
      name
      optional
      options {
        _id
        requiresModifierOptionIds
        requiresAtLeastOneModifierOption
        availabilityAt(menuId: $menuId) {
          available
          menuId
          price
        }
        max
        name
      }
    }
    informativeStamps
    promotions {
      _id
      promotionType
    }
    filterIds
    timesPurchased
  }
`

const productListInformationWithStockFragment = gql`
  fragment productListInformation on Product {
    ...productListInformationWithoutStock
    isOutOfStock(storeId: $storeId, deliverAt: $deliverAt)
  }
  ${productListInformationWithoutStockFragment}
`

export default productListInformationWithStockFragment
